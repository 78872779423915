<template>
	<div class="product-detail-page">
		<Breadcrumb />

		<template v-if="!loading">
			<div class="product-params-section" ref="productParams" :class="{'fixed-top':paramSectionIsFixed}">
				<section class="container">
					<div class="product-params">
						<span class="product-type ellipsis">{{ product.productName }} {{ product.summary }}</span>
						<div class="param-content">
							<!-- <span
								v-if="product.poster || (product.productIntroductions&&product.productIntroductions.length)"
								@click="toParam('Introduction')">
								{{ $i18n.t('productDetail.introduction') }}
							</span>
							<span @click="toParam('Specifications')">
								{{ $i18n.t('productDetail.specifications') }}
							</span>
							<span v-if="isAccessory" @click="toParam('Dimensions')">
								{{ $i18n.t('productDetail.dimensions') }}
							</span>
							<span v-if="product.remark" @click="toParam('Remarks')">
								{{ $i18n.t('productDetail.remarks') }}
							</span> -->
							<!--              <span @click="toParam('Accessory')">{{ $i18n.t('productDetail.accessory') }}</span>-->
							<!--              <span @click="toParam('Download')">{{ $i18n.t('productDetail.download') }}</span>-->
						</div>
					</div>
				</section>
			</div>

			<div v-show="paramSectionIsFixed" class="padding-blank" />
			<!-- 商品图片展示 -->
			<div class="product-section">
				<section class="container">
					<div class="product-summary">
						<div class="product-summary__view">

							<swiper-view :data-list="product.previews"></swiper-view>

							<!-- <div v-show="product.productClass" style="text-align: center;">
								<el-button type="text" icon="el-icon-plus" @click="addToCompare(product)">add to compare</el-button>
							</div> -->

							<!-- 后续 el-carousel 与 class="product-image-content" 都会被 swiper-view 取代 -->
							<!-- <el-carousel title="大图" :interval="5000" arrow="never" ref="img" :initial-index="0" height="400px"
								:autoplay="false" indicator-position="none">
								<el-carousel-item v-for="(item,index) in product.sizeImageUrl" :key="index">
									<div v-if="index==1" id="youtube-player"></div>
									<el-image v-else class="product-image" fit="scale-down" align="center" :src="domainUrl+item | prefixFilter" alt="" />
								</el-carousel-item>
							</el-carousel> -->

							<!-- <div class="product-image-content">
								<div class="swiper product-image-swiper" ref="productImageList">
									<div class="swiper-wrapper" title="小图">
										<template v-for="(item,index) in product.sizeImageUrl">
											<div class="swiper-slide product-image-item swiper-no-swiping"
												:class="{'product-image-item-active': currentProductImageIndex % product.sizeImageUrl.length === index}"
												:key="index" @click="pickProductImage(index)">
												<el-image :src="domainUrl+item | prefixFilter" fit="scale-down" />
											</div>
										</template>
									</div>
								</div>

								<div class="swiper-button-prev" @click="productImagePrev"></div>
								<div class="swiper-button-next" @click="productImageNext"></div>
							</div> -->
						</div>
						<div class="product-summary__param">
							<div class="product-name">{{ product.productName }}</div>
							<div class="product-type">{{ product.summary }}</div>
							<ul class="summary-desc">
								<li v-for="(item,index) in product.description" :key="index">{{ item }}</li>
							</ul>
						</div>
					</div>
				</section>
			</div>
			

			<div class="info-section" ref="Introduction"
				v-if="product.poster || (product.productIntroductions&&product.productIntroductions.length)">
				<section class="container">
					<div class="section-title">{{ $i18n.t("productDetail.introduction") }}</div>
					<el-image v-if="product.poster" :src="product.poster" fit="scale-down" width="100%" />
					<ul v-if="product.productIntroductions" class="introduct-info">
						<el-row :gutter="40" type="flex" style="flex-wrap: wrap">
							<el-col :md="12" :xs="24" v-for="(intro, index) in product.productIntroductions"
								:key="index">
								<li :key="index" class="introduct-item">
									<div class="introduct-title layout-row">
										<el-image :src="domainUrl+intro.imageUrl" class="introduct-icon" />
										<div>{{ intro.name }}</div>
									</div>
									<ul>
										<li v-for="item in intro.value" class="introduct-sub-item">{{ item }}</li>
									</ul>
								</li>
							</el-col>
						</el-row>
					</ul>
				</section>
			</div>

			<div class="info-section" ref="Specifications" v-if="isSDC||isIVS">
				<section class="container">
					<div class="section-title">{{ $i18n.t('productDetail.specifications') }}</div>
					<div class="search-box">
						<div class="search">
							<el-input :placeholder="$i18n.t('common.querySpecifications')" size="small" clearable
								v-model.trim="keyWord" @keyup.native.enter="search" @clear="isShowSearchResult=false">
								<el-button slot="append" icon="el-icon-search" @click="search" />
							</el-input>
						</div>
						<div class="search-result" v-show="isShowSearchResult">
							<h3 class="search-result-title">
								<span>{{ $i18n.t('products.found') }} ({{ resultNum }})</span>
							</h3>
							<div class="search-result-content">
								<template v-if="!resultNum">
									<div class="no-result">{{ $i18n.t('products.noContent') }}</div>
								</template>
							</div>
						</div>
					</div>
					<div class="specification-wrap">
						<div class="specification-type-col custom-scroll" dir="rtl">
							<template v-for="(item,index) in product.specification">
								<div :key="index" class="type-col-item"
									:class="{'type-col-item-active':specification===item.specName}" dir="ltr"
									@click="specification=item.specName">
									{{ item.specName }}
								</div>
							</template>
						</div>
						<div class="specification-content-col custom-scroll">
							<table class="content-table" ref="contentTable">
								<tbody>
									<template v-for="(item,index) in currentSpecification">
										<tr :key="index">
											<td class="content-table-title">{{ item.name }}</td>
											<td>{{ item.value }}</td>
										</tr>
									</template>
								</tbody>
							</table>
							<div class="content-fixed-handle">
								<el-button icon="el-icon-document-copy" size="small" @click="clickCopy">
									{{ $i18n.t('productDetail.copy') }}
								</el-button>
							</div>
						</div>
					</div>
				</section>
			</div>

			<div class="info-section" ref="Specifications" v-else>
				<section class="container">
					<div class="section-title">{{ $i18n.t('productDetail.technicalSpec') }}</div>
					<div v-if="Object.keys(newSpecification).length === 0" class="specification-wrap">
						<div class="specification-content-col custom-scroll">
							<table class="content-table" ref="contentTable">
								<tbody>
									<template v-for="(item,index) in currentSpecification">
										<tr :key="index">
											<td class="content-table-title">{{ item.name }}</td>
											<td style="white-space: break-spaces;">{{ item.value }}</td>
										</tr>
									</template>
								</tbody>
							</table>
							<div class="content-fixed-handle">
								<el-button icon="el-icon-document-copy" size="small" @click="clickCopy">
									{{ $i18n.t('productDetail.copy') }}
								</el-button>
							</div>
						</div>
					</div>
					<div v-if="Object.keys(newSpecification).length > 0" class="specification-wrap1">
						<div class="specification-content-col custom-scroll" style="padding: 10px 20px;">
							<el-collapse v-model="activeNames">
								<el-collapse-item v-for="(item, i) in Object.keys(newSpecification)" :title="item" :name="i" :key="i">
									<div>
										<table class="content-table" style="width: 100%;">
											<tbody>
												<tr v-for="(obj, key) in newSpecification[item]" :key="key" style="border-bottom: 1px solid #f2f2f2;">
													<td class="content-table-title" style="width: 233px;">{{ obj['property'] }}</td>
													<td style="white-space: break-spaces;">{{ obj['value'] }}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</el-collapse-item>
							</el-collapse>
						</div>
					</div>
				</section>
			</div>

			<div class="info-section" ref="Dimensions" v-if="isAccessory">
				<section class="container">
					<div class="section-title">{{ $i18n.t("productDetail.dimensions") }}</div>
					<div class="dimensions-info" v-if="product.sizeImageUrl&&product.sizeImageUrl.length">
						<el-image v-for="(item,index) in product.sizeImageUrl" :src="item" :key="index" alt="" />
					</div>
				</section>
			</div>

			<div class="info-section" ref="Remark" v-if="product.remark">
				<section class="container">
					<div class="section-title">{{ $i18n.t("productDetail.remarks") }}</div>
					<div class="remark-info">
						<div class="remark-term">{{ product.remark.title }}：</div>
						<div v-for="(item,index) in product.remark.remark" :key="index" class="remark-item">
							{{ index + 1 }}、{{ item }}
						</div>
					</div>
				</section>
			</div>

			<template v-if="product.accessoriesProducts.length > 0">
				<div class="info-section" ref="Accessory">
					<section class="container">
						<div class="section-title">{{ $i18n.t('productDetail.accessory') }}</div>
						<div class="accessory-content">
							<div class="swiper accessory-swiper" ref="accessory">
								<div class="swiper-wrapper">
									<template v-for="(item,index) in product.accessoriesProducts">
										<div class="swiper-slide accessory-card" :key="index" @click="toPage(item)">
											<div class="accessory-card-image">
												<img :src="domainUrl+item.imageUrl | prefixFilter" alt="">
											</div>
											<div class="accessory-card-main">
												<div class="accessory-card-name ellipsis" :title="item.name">
													{{ item.name }}
												</div>
												<div class="accessory-card-type" :title="item.summary">
													{{ item.summary }}
												</div>
												<!-- <div class="accessory-card-bomid ellipsis" :title="item.bomid">
													({{ item.bomid }})</div> -->
											</div>
										</div>
									</template>
								</div>
							</div>
							<div class="swiper-button-prev" @click="accessoryPrev"></div>
							<div class="swiper-button-next" @click="accessoryNext"></div>
						</div>
					</section>
				</div>
			</template>

		</template>

		<div v-show="docs.length" class="info-section" ref="Download">
			<section class="container">
				<div class="section-title">{{ $i18n.t('productDetail.documentation') }}</div>
				<el-row class="resouse-list">
					<el-col :md="12" v-for="(item, index) in docs" :key="index">
						<li class="layout-row resouse-item">
							<div class="resouse-name">{{item.title}}</div>
							<div class="resouse-link" @click="clickAction(item)">{{ $i18n.t('productDetail.clicktoLoad' )}}</div>
							<!-- <a href="https://e.huawei.com/en/material/holosens/6a91723988d740f68f3b9bff98b79b37"
								target="_blank" class="resouse-link">Click here to download</a> -->
						</li>
					</el-col>
				</el-row>
			</section>
		</div>

		<!-- <div class="container" style="padding: 20px 0">
			<el-skeleton :rows="6" animated :loading="loading">
				<template slot="template">
					<el-row type="flex" justify="space-between" style="margin-bottom: 20px">
						<el-col :span="3">
							<el-skeleton-item variant="text" />
						</el-col>
						<el-col :span="6">
							<el-skeleton-item variant="text" />
						</el-col>
					</el-row>
					<div class="product-summary">
						<div class="product-summary__view">
							<el-skeleton-item variant="image" style="width: 100%; height: 400px;" />
							<el-row type="flex" :gutter="10" style="margin-top: 10px">
								<el-col v-for="i in 3" :key="i">
									<el-skeleton-item variant="image" style="width: 100%; height: 120px;" />
								</el-col>
							</el-row>
						</div>
						<div class="product-summary__param" style="padding-right: 0">
							<el-skeleton-item variant="h3" />
							<el-skeleton-item variant="h3" />
						</div>
					</div>

				</template>
			</el-skeleton>
		</div> -->
		<!-- <CompareList/> -->
	</div>
</template>
<script>
	import Breadcrumb from '../../../components/Breadcrumb';
	import detail from './detail';
	import { debounce } from 'lodash';
	import { getWindowScrollOffset } from '../../../utils';
	import Swiper from 'swiper';
	import SwiperView from './components/swiper.vue'
	import 'swiper/swiper-bundle.css';
	import { getProductDetail,getDocByProductName,getSpecDetailsByProductId } from '../../../api/product';
	// import { videoPlayer } from "vue-video-player";
	import "video.js/dist/video-js.css";
	import {getList} from "@/api/documentation"
	import {mapActions} from "vuex"
	import CompareList from "../list/components/CompareList.vue";
	export default {
		name: 'detail',
		components: {
			Breadcrumb,
			// videoPlayer,
			SwiperView,
			CompareList
		},
		data() {
			return {
				loading: true,
				type: null,
				product: {
					imageUrl: [],
					specification: [],
					accessoriesProducts: [],
				},
				currentProductImageIndex: 0,
				specification: 'Basic Spec',
				keyWord: '',
				paramSectionIsFixed: false,

				productImageListSwiper: null,
				accessorySwiper: null,

				resultNum: 0,
				isShowSearchResult: false,

				productParamsOffsetTop: 0,

				player: null,
				/*
				playerOptions: {
					playbackRates: [0.5, 1.0, 1.5, 2.0], //播放速度
					autoplay: false, //如果true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: true, // 导致视频一结束就重新开始。
					preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: "zh-CN",
					aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: "video/mp4",
						src: "", //视频url地址
					}],
					poster: "", //你的封面地址
					// width: document.documentElement.clientWidth,
					notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						timeDivider: true,
						durationDisplay: true,
						remainingTimeDisplay: false,
						fullscreenToggle: true, //全屏按钮
					},
				},
				*/
				docs: [],
				newSpecification: {},
				activeNames: [0]
			}
		},
		computed: {
			currentProductImage() {
				// return this.product?.imageUrl[this.currentProductImageIndex];
				return this.product?. [this.currentProductImageIndex];
			},
			currentSpecification() {
				return this.product.specification.find(i => i.specName === this.specification)?.specificationItems
			},
			isSDC() {
				return this.type === 'SDC'
			},
			isIVS() {
				return this.type === 'IVS'
			},
			isAccessory() {
				return this.type === 'Accessory'
			},
		},
		mounted() {
			this.initData();
		},
		filters: {
			prefixFilter(val) {
				if (val) {
					return val.replace('https://resources.holowits.com.sg/', '')
				}
				return val
			}
		},
		methods: {
			...mapActions({
				addComparisonList: 'compare/addComparisonList'
			}),
			addToCompare(item) {
				this.addComparisonList(item)
			},

			async getSpecDetailsByProductId(productType, productId) {
				const {data: {isSuccess, data}} = await getSpecDetailsByProductId({
					productType,
					productId
				})
				if (isSuccess) {
					let obj = {}
					for (let item of data) {
						if (item.value !== 'N/A') {
							(obj[item.category] || (obj[item.category] = [])).push(item)
						}
					}

					this.newSpecification = obj
				}
			},

			initYoutubeVideo(videoId) {
				let c = document.getElementById('youtube');
				// if (!c) {
					let tag = document.createElement('script');
					tag.setAttribute('id', 'youtube');
					tag.src = 'https://www.youtube.com/iframe_api';
					let scriptTag = document.getElementsByTagName('script')[0];
					scriptTag.parentNode.insertBefore(tag, scriptTag);
				// }
				// window.onYouTubeIframeAPIReady = function() {
				// }
				setTimeout(() => {
					this.player = new YT.Player('youtube-player', {
						width: '600',
						height: '400',
						videoId: videoId,
					});
					let ytb = document.getElementById('youtube-player');
					ytb.src = ytb.src + '&rel=0';
				}, 3000);
			},
			toPage(item) {
				if (item.status === 'online') {
					this.$router.push({
						path: `/product-center/detail/${item.type}/${item.accProductId}`
					})
				}
			},
			async clickAction(item) {
				// 如果传入的值为 网络路径 则直接跳转对应页面
				if (item.type === false) {
					window.open(item.value);
					return false;
				}
				// 根据文件名查询文件路径
				const {data: {data, isSuccess}} = await getList({
					size: 1,
					model: {
						keyWords: item.value
					}
				})
				if (isSuccess) {
					if (data.records.length) {
						let value = data.records[0]
						if (!value.viewUrl.includes('https://resources.holowits.com/')) {
							window.open('https://resources.holowits.com/'+value.viewUrl)
						} else {
							window.open(value.viewUrl)
						}
					}
				}
			},
			async getDocByProductName(productId) {
				const {data: {data, isSuccess}} = await getDocByProductName({
					productId: productId
				})
				if (isSuccess) {
					this.docs = data
				}
			},
			initData() {
				let param = {
					// 'language': this.$i18n.locale,
					'language': 'en',
					'productId': this.$route.params.productId,
					'type': this.$route.params.type,
				};
				this.type = this.$route.query.type
				this.loading = true
				getProductDetail(param).then(res => {
					
					this.product = res.data.data;
					// if (this.product.imageUrl[0] === this.product.sizeImageUrl[0]) {
					// 	this.product.sizeImageUrl = [...this.product.imageUrl];
					// } else {
					// 	this.product.sizeImageUrl = [...this.product.imageUrl, ...this.product.sizeImageUrl];
					// }
					
					// if (this.product.videoUrl) {
					// 	setTimeout(() => {
					// 		this.initYoutubeVideo(this.product.videoUrl.split('/embed/')[1]);
					// 	}, 0)
					// // 	this.playerOptions.sources[0].src = this.product.videoUrl
					// // 	this.playerOptions.poster = 'https://resources.holowits.com/'+ this.product.sizeImageUrl[1]
					// }
					setTimeout(() => {
						this.product.specification = res.data.data.specification.filter(
							item => item && item.specificationItems && item.specificationItems
							.length)
						// console.log(this.product.specification)
						this.specification = this.product.specification[0].specName

						this.productImageListSwiper = new Swiper(this.$refs.productImageList, {
							slidesPerView: 'auto',
							paginationClickable: true,
							spaceBetween: 20,
							noSwiping: true,
							on: {
								slideChangeTransitionStart: (e) => {
								},
							},
						});

						this.accessorySwiper = new Swiper(this.$refs.accessory, {
							slidesPerView: 'auto',
							paginationClickable: true,
							spaceBetween: 20,
						});

						this.productParamsOffsetTop = getWindowScrollOffset().y +
							this.$refs.productParams.getBoundingClientRect().top;
							console.log(this.$refs.productParams.getBoundingClientRect().top)
						window.addEventListener('scroll', this.scrollMouse);
						//手动调用滚动回调
						this.scrollMouse();
					}, 500)

					return res;
				}).then(({data: {data: {productId, productClass}}}) => {
					this.getSpecDetailsByProductId(productClass, productId)
					this.getDocByProductName(productId)
				}).finally(() => {
					setTimeout(() => {
						this.loading = false
					}, 300)
				});
			},

			search() {
				if (this.keyWord) {
					this.isShowSearchResult = true;
				} else {
					this.isShowSearchResult = false;
				}
			},

			pickProductImage(index) {
				// this.productImageListSwiper.slideTo(index);
				if (index !== 1 && this.player) {
					this.player.pauseVideo();
				}
				this.currentProductImageIndex = index;
				this.$refs.img.setActiveItem(index)
			},
			productImagePrev() {
				if (this.currentProductImageIndex > 0) {
					this.currentProductImageIndex--;
				} else {
					this.currentProductImageIndex = this.product.sizeImageUrl.length - 1;
				}
				this.player.pauseVideo();
				// if (this.currentProductImageIndex !== this.productImageListSwiper.activeIndex - 1) return;
				// this.productImageListSwiper.slidePrev();
				this.$refs.img.prev()
			},
			productImageNext() {
				this.currentProductImageIndex++;
				this.player.pauseVideo();
				// if (this.currentProductImageIndex < this.product.imageUrl.length - 1) {
				// 	this.currentProductImageIndex++;
				// }
				// this.productImageListSwiper.slideNext();
				this.$refs.img.next()
			},

			accessoryPrev() {
				this.accessorySwiper.slidePrev();
			},
			accessoryNext() {
				this.accessorySwiper.slideNext();
			},

			scrollMouse: debounce(function(e) {
				var top = getWindowScrollOffset().y;
				this.paramSectionIsFixed = top >= this.productParamsOffsetTop;
			}, 10, {
				'maxWait': 10,
				'leading': false
			}),

			toParam(refName) {
				let {
					offsetTop
				} = this.$refs[refName];
				window.scroll({
					top: offsetTop,
					behavior: 'smooth',
				});
			},

			clickCopy() {
				var text = this.$refs.contentTable.innerText;
				var textareaDom = document.createElement('textarea');
				textareaDom.value = text;
				document.body.appendChild(textareaDom);
				textareaDom.select();
				document.execCommand('Copy');
				textareaDom.style.display = 'none';
				textareaDom.remove();
				this.$message.success(this.$i18n.t('tipInfo.copySuccess'));
				// alert(i18n.t("tipInfo.copySuccess"))
			},
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.scrollMouse);
		},
	};
</script>
<style lang="less">
.page-on-scroll {
	.navbar {
		border-bottom: none !important;
	}
}
</style>
<style scoped lang="less">
	
	.padding-blank {
		height: 68px;
	}

	.product-image-content {
		position: relative;
		min-height: 112px;

		.swiper-button-prev,
		.swiper-button-next {
			color: #999999;

			&:after {
				font-size: 30px;
			}
		}

		.swiper-button-prev {
			left: 0;
		}

		.swiper-button-next {
			right: 0;
		}

		.product-image-swiper {
			padding: 10px;
			margin: 0 30px;
		}

		.swiper-wrapper {
			//transition-timing-function: linear;
		}

		.product-image-item {
			width: 142px;
			border: 1px solid transparent;
			cursor: pointer;
			will-change: border;
			user-select: none;
			padding: 14px;
			height: 112px;

			&.product-image-item-active {
				border: 1px solid var(--themeColor);
			}

			/deep/ .el-image {
				width: 100%;
				height: 100%;
				object-fit: scale-down;
			}
		}
	}

	.accessory-content {
		position: relative;

		.swiper-button-prev,
		.swiper-button-next {
			color: #999999;
		}

		.accessory-swiper {
			padding: 10px;
			margin: 0 50px;
		}
	}


	.accessory-card {
		width: 232px;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
		cursor: pointer;
		border-radius: 6px;
		overflow: hidden;
		height: auto;

		.accessory-card-image {
			background: rgb(249 249 249);
			user-select: none;

			img {
				width: 100%;
				height: 235px;
				object-fit: scale-down;
			}
		}

		.accessory-card-main {
			background-color: #FFFFFF;
			text-align: center;
			padding: 8px 10px;
			line-height: 1.6;
			height: calc(100% - 235px);
		}

		.accessory-card-name {}

		.accessory-card-type {
			font-size: 16px;
		}

		.accessory-card-bomid {
			font-size: 12px;
		}
	}

	.resouse-list {
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
		padding: 60px 0 30px;
		background: rgb(255, 255, 255);
		flex-wrap: wrap;
		// border: 1px solid rgb(229, 237, 243);
		// box-shadow: 0 6px 36px 0 rgba(0, 62, 100, .04);
		border-radius: 4px;
	}

	.resouse-item {
		letter-spacing: 0;
		margin-bottom: 30px;
		padding: 0 30px 0 42px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		flex-direction: row;

		&:before {
			content: "";
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background: rgba(56, 56, 56, 0.35);
			margin-right: 17px;
			display: inline-block;
		}
	}

	.resouse-name {
		font-size: 16px;
		color: rgb(0, 0, 0);
		display: inline-block;
		// width: 220px;
		width: 180px;
	}

	.resouse-link {
		// flex: 1;
		// font-size: 14px;
		// color: rgb(155, 178, 237);
		// display: inline-block;
		cursor: pointer;
		border: 1px solid rgba(199, 0, 11, 1);
    	padding: 5px 11px 5px 11px;
    	border-radius: 224px;
    	color: rgba(199, 0, 11, 1);
    	font-size: 16px;
    	display: flex;
    	justify-content: flex-start;
    	align-items: flex-start;
	}

	.specification-wrap {
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .08);
		background: rgb(255, 255, 255);
		position: relative;
		margin-top: 20px;
		display: flex;
		min-height: 400px;
		max-height: 718px;
	}

	.specification-wrap1 {
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .08);
		background: rgb(255, 255, 255);
		position: relative;
		margin-top: 20px;
		display: flex;
	}

	.specification-type-col {
		min-width: 180px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.type-col-item {
		width: 100%;
		height: 80px;
		line-height: 80px;
		text-align: center;
		font-size: 16px;
		color: rgb(40, 43, 51);
		padding: 0 20px;
		background: rgb(240, 242, 245);
		cursor: pointer;
		box-sizing: border-box;
		white-space: normal;
	}

	.type-col-item-active {
		background: rgb(255, 255, 255);
		color: rgb(199, 0, 11);
	}

	.specification-content-col {
		flex: 1;
		overflow-x: auto;
		overflow-y: auto;
		padding: 10px 30px 10px 80px;
		box-sizing: border-box;


		.content-fixed-handle {
			position: absolute;
			bottom: 20px;
			right: 80px;
		}

		.content-table {
			td {
				height: 36px;
				line-height: 22px;
				font-size: 14px;
				color: rgb(40, 43, 51);
				word-break: break-all;
				padding: 4px 24px 4px 0;
				min-width: 200px;
			}

			tr {
				td:last-child {
					min-width: 300px;
				}
			}

			.content-table-title {
				white-space: nowrap;
				color: rgb(109, 114, 120);
			}
		}

		/deep/.el-collapse-item__header {
			font-size: 20px;

			&.is-active {
				color: #C7000B;
			}
		}
	}


	.product-detail-page>.info-section {
		padding-top: 60px;
		padding-bottom: 60px;
		&:nth-child(even) {
			// background-color: rgb(252, 252, 252);
			padding-top: 0px;
			padding-bottom: 60px;
		}

		.section-title {
			height: 40px;
			line-height: 40px;
			font-size: 30px;
			color: rgb(0, 0, 0);
			border-left: 6px solid rgb(199, 0, 11);
			padding-left: 32px;
			margin-bottom: 30px;
		}

	}

	.gray-bg {
		background-color: rgb(252, 252, 252);
	}

	.search-box {
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .08);
		background: rgb(255 255 255);

		.search {
			padding: 20px 40px;

			.el-input {
				max-width: 500px;
			}
		}

		.search-result {
			padding: 20px 40px 20px;
			background-color: #f7fafb;
			border-top: 1px solid #e6e6e6;

			.search-result-title {
				color: rgb(40, 43, 51);
				padding-bottom: 8px;
				font-size: 14px;
				font-weight: bold;
				border-bottom: 1px solid #e6e6e6;

				span {
					position: relative;
					display: inline-block;

					&:after {
						content: '';
						position: absolute;
						left: 0;
						right: 0;
						bottom: -9px;
						height: 1px;
						background-color: var(--themeColor);
					}
				}
			}

			.search-result-content {
				padding: 20px 0;

				.no-result {
					font-size: 14px;
					color: rgb(109, 114, 120);
					line-height: 24px;
				}
			}
		}
	}

	.product-section {
		background-color: rgb(252, 252, 252);
	}

	.product-summary {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		justify-content: center;

		.product-summary__view {
			width: 600px;
			// height: 484px;
			display: block;
			max-width: 100%;

			.product-image {
				display: block;
				width: 100%;
				object-fit: scale-down;
				background-color: rgb(247, 247, 247);
				height: 440px;
			}
		}

		.product-summary__param {
			flex: 1;
			width: 100%;
			min-width: 300px;
			padding: 10px 20px 10px 60px;
			font-size: 30px;
			line-height: 1.2;

			.product-type {
				color: rgb(0, 0, 0);
				margin-bottom: 40px;
			}

			.product-name {
				color: rgb(217, 39, 43);
				margin: 20px 0;
			}
		}

		/deep/.video-js {
			.vjs-big-play-button {
				top: 160px;
				left: 230px;
			}

			width: 100% !important;
			height: calc(100vh - 536px) !important;
			// height: 100%;
			object-fit:fill; //消除留白
		}

		/deep/ .vjs-poster {
			background-color: #f7f7f7;
		}
	}

	.product-params-section {
		//background-color: #f9f9f9;
		background-color: #FFFFFF;
		//border-color: #e5e5e5;
		border-color: #f4f8f9;
		border-style: solid;
		border-width: 0;
		//border-top-width: 1px;
		border-bottom-width: 1px;
		position: relative;
		z-index: -1;
		transition: .2s;

		&.fixed-top {
			position: fixed;
			top: 100px;
			left: 0;
			right: 0;
			z-index: 99;
			border-bottom: 1px solid rgb(230, 239, 243);
		}
	}

	.product-params {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 16px;
		min-height: 68px;
		//flex-wrap: wrap;

		.param-content {
			display: flex;
			align-items: center;
			flex: 1;
			width: 100%;
			justify-content: flex-end;

			span {
				padding: 4px 14px;
				position: relative;
				cursor: pointer;

				&:hover {
					color: #333333;
				}

				&:after {
					content: '';
					position: absolute;
					right: 0;
					top: 4px;
					bottom: 4px;
					width: 1px;
					background-color: #999999;
				}

				&:last-child:after {
					display: none;
				}
			}
		}
	}

	.introduct-info {
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;
		overflow: hidden;
	}

	.introduct-item {
		margin-top: 30px;
		margin-bottom: 30px;
		overflow: hidden;
	}

	.introduct-icon {
		width: 68px;
		min-width: 68px;
		max-width: 68px;
		margin-right: 20px;
	}

	.introduct-title {
		font-size: 30px;
		font-weight: bold;
		color: #C7000B;
		line-height: 38px;
	}

	.introduct-sub-item {
		line-height: 18px;
		margin-top: 20px;
		font-size: 14px;
		margin-left: 90px;
		color: #000000;
		list-style: circle inside;
	}

	.dimensions-info {
		/deep/ .el-image {
			max-width: 400px;
			margin: 80px auto;
			display: block
		}
	}

	.remark-info {
		padding: 50px 120px;
		background: #FFFFFF;
		box-shadow: 0 6px 36px 0 rgba(0, 62, 100, 0.04);
		border-radius: 4px;
	}

	.remark-term {
		font-size: 16px;
		color: #000000;
		font-weight: bold;
		margin-bottom: 15px;
	}

	.remark-item {
		font-size: 14px;
		color: #000000;
		line-height: 24px;
		margin-top: 8px;
	}


	.summary-desc {
		margin-top: 30px;
		padding-left: 10px;

		li {
			font-size: 14px;
			color: #000000;
			margin-top: 10px;
			line-height: 18px;
			padding-left: 10px;
			list-style: circle;
		}
	}

	@media (max-width: 991px) {
		.resouse-list {
			padding: 30px 0 0;
		}

		.resouse-item {
			padding: 0 30px;
		}
	}
</style>
